<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t("return_balance.head") }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t("return_balance.head_list") }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <span>
              <date-picker-input ref="startDate" :date="dateFrom" :label="$t('return_balance.date_from')"
                @setValue="dateFrom = $event" />
            </span>
            <span>
              <date-picker-input ref="endDate" :date="dateTo" :label="$t('return_balance.date_to')"
                @setValue="dateTo = $event" />
            </span>
            <span class="mr-2" style="display: flex;">
              <div
                style="width: 98px; height: 38px; text-agent: center; background: #e5e5e5; border-radius: 8px; color: #737076; display: flex; justify-content: center; align-items: center;">
                {{ $t('return_balance.type') }} </div>
              <v-select :items="roles" item-text="label" v-model="valType" outlined dense style="max-width: 110px;" />
            </span>
            <v-btn color="primary" @click="searchTurnBonus" class=""> {{ $t('search') }} </v-btn>
          </v-row>
          <!-- <v-btn color="primary" class="px-5"> ค้นหา </v-btn> -->
        </v-card-actions>
        <v-card-title>
          {{ $t('return_balance.summary_bonus') }} : &nbsp;<span style="color: #28c76f !important">{{ total | currency
          }}</span></v-card-title>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5">{{ $t('search') }}:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')" :options.sync="pagination" :server-items-length="pagination.totalItems"
            :items-per-page="pagination.rowsPerPage" :loading="loading" :page.sync="pagination.currentPage" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :items="members">
            <template v-slot:[`item.amount`]="{ item }">
              {{ item.amount | currency }}
            </template>
            <template v-slot:[`item.winloss`]="{ item }">
              {{ item.winloss | currency }}
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      searchData: null,
      headers: [
        {
          text: this.$t('return_balance.tel'),
          value: 'user',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('return_balance.play'),
          value: 'winloss',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('return_balance.play_percent'),
          value: 'percent',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('return_balance.amount'),
          value: 'amount',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('return_balance.type'),
          value: 'type_game',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('return_balance.date'),
          value: 'date',
          width: '95px',
          align: 'center',
          sortable: false,
        },
      ],
      members: [],
      loading: false,
      valType: this.$t('all'),
      roles: [this.$t('all'), this.$t('casino'), this.$t('slot'), this.$t('sport')],
      pagination: {
        currentPage: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      total: 0,
      dummy: true,
    }
  },
  async created() {
    this.addLogPage()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        // await this.getTurnBonus(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
        if (cont && !this.dummy) await this.getTurnBonus(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
        if (cont && this.dummy) await this.getTurnBonus(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
          data = {
            ip: el.ip,
            name: 'โบนัสคืนยอดเสีย',
            url: window.location.href,
            detail: 'รายการโบนัสคืนยอดเสีย',
            admin: userSystem.name ? userSystem.name : '',
          }
        )))
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async searchTurnBonus() {
      this.dummy = false
      await this.getTurnBonus(1, this.pagination.rowsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
      this.pagination.currentPage = 1
    },
    async getTurnBonus(page, row, time_from, time_to, search) {
      this.loading = true
      this.members = []
      let total = await this.$store.dispatch('getCashbackTotal', {
        time_from: this.$refs.startDate.value,
        time_to: this.$refs.endDate.value
      })
      this.total = Number(total.sum_total).toFixed(2)

      let type_member = ''
      this.valType === 'คาสิโน' ? type_member = 'CASINO' :
        this.valType === 'สล็อต' ? type_member = 'SLOT' :
          this.valType === 'กีฬา' ? type_member = 'SPORT' :
            type_member = ''
      // console.log('this.valType :>> ', this.valType, type_member);

      let rs = await this.$store.dispatch('getCashback', {
        page: page,
        rows: row,
        ...(!search ? { page: page } : {}),
        ...(!search ? { rows: row } : {}),
        ...(time_from ? { time_from: this.formatDateYYYY(time_from) } : {}),
        ...(time_to ? { time_to: this.formatDateYYYY(time_to) } : {}),
        ...(search ? { search: search } : {}),
        type: type_member
      })
      this.pagination.totalItems = rs.count
      rs.data.forEach(element => {
        let type_game = 'ทั้งหมด'
        element.type_game === 'CASINO' ? type_game = 'คาสิโน' :
          element.type_game === 'SLOT' ? type_game = 'สล็อต' :
            element.type_game === 'SPORT' ? type_game = 'กีฬา' :
              type_game = 'ทั้งหมด'

        this.members.push({
          id: element.id,
          user: element.member ? element.member.phone : '',
          turnover: element.turnover,
          winloss: element.winloss,
          percent: element.percent,
          amount: element.amount,
          type_game: type_game,
          date: element.created_at ? moment(element.created_at).format('YY-MM-DD HH:mm') : '',
        })
      })
      this.loading = false
    },
    async searchKeyword(value) {
      this.dummy = true;
      this.searchData = value
      await this.getTurnBonus(1, this.pagination.itemsPerPage, null, null, value)
      this.pagination.currentPage = 1
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
  },
}
</script>
